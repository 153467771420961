.list-item-link {
  text-decoration: none;
  display: block;
  /* width: 400px; */
}

.list-item {
  display: flex;
  /* padding: 4px; */
  padding-top: 8px;
  margin-bottom: 64px;
  border-top: 1pt solid black;
  /* margin-left: 70px; */
}

.list-item-number {
  flex: 0 0 150px;
  padding-right: 40px; 
}

.list-item-content {
  /* flex: 0 0 400px; */
}

.list-item-description {
  color: #a4a4a4;
}

.list-item-title {
  margin-bottom: 20px;
}

@media (max-width: 740px) {
  .list-item {
    display: block;
  }

  .list-item-description {
    margin-top: 20px;
  }
}