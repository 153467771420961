.protocol-chart {
    position: relative;
    width: 100%; 
    height: 500px; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    /* background-color: #fbfbfb; */
    border-radius: 10px;
    font-size: 14px;
}

.protocol-chart::before,
.protocol-chart::after {
    content: '';
    position: absolute;
    background-color: var(--grey-1);
}

.protocol-chart::before {
    left: 50%;
    width: 1px;
    height: 100%;
}

.protocol-chart::after {
    top: 50%;
    height: 1px;
    width: 100%;
}

.protocol-point {
    position: absolute;
    text-align: center;
    transform: translate(-50%, -50%); 
    text-decoration: none; 
    color: black; 
    max-width: 100px;
    text-shadow: 1px 1px 0px rgb(255, 255, 255); 
    z-index: 9999;
}

.axis-label {
    position: absolute;
    font-weight: 600;
    background-color: white;
    border: 5px solid white;
    z-index: 999;
    user-select: none;
}

/* X-axis labels */
.x-label-hard {
    left: 0%;
    top: 50%;
    transform: translateY(-48%);
}

.x-label-soft {
    right: 0%;
    top: 50%;
    transform: translateY(-48%);
}

/* Y-axis labels */
.y-label-atomic {
    left: 50%;
    bottom: 0%;
    transform: translateX(-50%);
}

.y-label-systemic {
    left: 50%;
    top: 0%;
    transform: translateX(-50%);
}
