.protocol-detail-content {
  /* margin-left: 70px; */
}

.title {
  font-size: 19px;
  flex: 0 0 50vw;

}

.spectrum {
  border-top: 0.5px solid;
  margin-top: 50px;
}

.next {
  border-top: 0.5px solid;
  margin-top: 50px;
  padding-top: 50px;
  font-size: 20px;
  /* font-size: 70px; */
  /* text-align: right; */
}

.protocol-detail-header {
  display: flex;
}

.home-link {
  display: flex;
}

.protocol-detail-number {
  flex: 0 0 50px;
  padding-right: 20px;
  font-size: 19px;
}

@media (max-width: 740px) {
  .protocol-detail-content {
    margin-left: 0px;
  }
}