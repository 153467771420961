.page-title {
    display: flex;
    /* padding: 4px; */
    /* margin-top: 20px; */
    margin-bottom: 30px;
  }
  
  .page-title-empty {
    /* flex-basis: 70px;  */
    flex-grow: 0;
    flex-shrink: 0;
  }
  
  .page-title-main {
    flex-grow: 1;
    /* font-size: 18px; */
    font-family: 'Acumin';
    font-weight: 500;
  }
  
  .page-title-main:hover {
  /* opacity: 0.5; */
}

