.expandable-section {
    margin-bottom: 80px;
}

.expandable-content {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1pt solid black;
    max-height: 0;
    overflow: hidden;
}

.expandable-section.open .expandable-content {
    max-height: 1000px;
}

button {
    background-color: white;
    /* color: #999999; */
    /* font-size: 20px; */
    /* font-weight: 600; */
}

button:hover {
    cursor: pointer;
    opacity: 0.6;
}