.question-answer:hover .question{
    color: #909090;
}

.question-answer:hover .answer{
    color: #000000;
}

.question {
    margin-top: 16px;
}

.answer {
    color: #909090;
}