.navigation-links {
  margin-left: 74px;
}

.protocol-nav-link {
  /* font-size: 19px; */
  margin-right: 10px;
}

.app {
  display: flex;
  justify-content: center;
}

/* .app {
  display: flex;
}

.info {
  width: 400px;
  background-color: rgb(244, 244, 244);
  padding: 20px;
  border-radius: 10px;
}


.chart {
  position: fixed;
  top: 50px; 
  right: 50px; 
  width: 1000px;
} */

.protocols-header {
  margin-bottom: 5px;
  font-weight: 600;
}

.rose-icon {
  position: fixed;
  padding: 10px;
  top: 0;  
  left: 0;  
  width: 32px; 
  height: 32px;  
  z-index: 1000;  
}

.rose-icon:hover {
  cursor: pointer;
  opacity: 0.6;
}