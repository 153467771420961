p, a, html, body, button, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;;
    font: inherit;
    vertical-align: baseline;
    text-decoration: none;
}

a {
  color: black;
}

@font-face {
  font-family: 'Acumin';
  src: url('./assets/fonts/AcuminPro-Regular.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Acumin';
  src: url('./assets/fonts/AcuminPro-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Acumin';
  src: url('./assets/fonts/AcuminPro-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Acumin';
  src: url('./assets/fonts/AcuminPro-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Allainz';
  src: url('./assets/fonts/ESAllianzTRIAL-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Allainz';
  src: url('./assets/fonts/ESAllianzTRIAL-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Allainz';
  src: url('./assets/fonts/ESAllianzTRIAL-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Allainz';
  src: url('./assets/fonts/ESAllianzTRIAL-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

:root {
  --grey-1: #b7b7b7;
}

a {
  cursor: pointer;
}

a:hover {
  opacity: 0.5;
}

body {
  font-family: 'Acumin', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  margin-left: 100px;
  max-width: 600px;
  padding: 20px;
}

b {
  font-weight: bold;
}

h2 {
  font-size: 22px;
  line-height: 26px;
}

.content-container {
  /* max-width: 300px;  */
}

.page-title, .list-item {
  margin-bottom: 10px;
}

@media (max-width: 740px) {
  body {
    margin-top: 40px;
      margin-left: 0px;
      width: 90%;
      padding-left: 20px;
  }
}