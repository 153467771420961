.spectrum-continuum {
    /* max-width: 600px; */
    margin: 0 auto;
    padding-top: 8px;
    margin-bottom: 16px;
  }
  
  /* .spectrum-row-full {
    width: 100%;
    align-content: center;
  } */

  .spectrum-row{
    align-items: center;
    display: flex;
    padding-top: 4px;
    padding-bottom: 2px;
  }


  .spectrums {
    /* min-width: 300px; */
  }

  .spectrum-row-full:hover {
    background-color: #f7f7f7;
  }
  
  .spectrum-label {
    width: 210px;
  }
  
  .spectrum-label:first-child {
    text-align: right;
    padding-right: 15px;
  }
  
  .spectrum-label:last-child {
    padding-left: 15px;
  }
  
  .spectrum-container {
    /* flex-grow: 1; */
    height: 10px;
    position: relative;
    width:130px;
  }
  
  .spectrum-dot {
    position: absolute;
    top: 30%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    /* background-color: #ebebeb; */
    border: 1px solid #bbbbbb;
    transform: translate(-50%, -50%);
  }
  
  .spectrum-dot.active {
    background-color: black;
  }

  .subtitle {
    margin-bottom: 20px;
  }

  @media (max-width: 740px) {
    .spectrum-label {
      width: 33%;
    }
  }
